/* global module */
import React, {Fragment} from 'react';
import {hot} from 'react-hot-loader';

import WaitBooking from './WaitBooking';
import DestinationView from './DestinationView';
import SearchContext from 'airborne/search2/SearchContext';
import HomepageButton from 'airborne/search2/HomepageButton';
import AirDestinationSearch from 'airborne/homepage2/AirDestinationSearch';

import '../../../midoffice/less/bootstrap-search.less';

import AirFilters from './AirFilters';
import FareGroups from './FareGroups';


@hot(module)
export default class SearchAir extends React.Component {
    render () {
        return (<Fragment>
            <DestinationView>
                <AirDestinationSearch editable hideTabs />
            </DestinationView>
            <div className="container clearfix">
                <div className="a-sidebar">
                    <HomepageButton />
                    <SearchContext />
                    <AirFilters />
                </div>
                <FareGroups />
            </div>
            <WaitBooking />
        </Fragment>);
    }
}
